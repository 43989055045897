body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff !important;
}
body a {
	color: unset!important;
	text-decoration: none!important;
}
.bodys{
 background: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
	margin: 0;
	padding: 0;
}
h1,ul,li {
  list-style: none !important;
  margin: 0 ;
  padding: 0 ;
}
.ptop50{
  padding-top: 50px !important;
}
.ptop20{
  padding-top: 20px;
}
.ml10{
  margin-left: 10px;
}
.am-tab-bar-tab-icon div img {
  width: 100%;
}
.user_down{
  text-align: center;
  /*padding-right: 10px; */
  bottom: 0;
  position: fixed;
width: 100%;
z-index:10;
}
.user_down button{
  background-color: #ff7300;
  color: #fff;
  border: 0;
  width: 100%;
  border-radius: 4px;
  padding: 15px 0;
  font-size: 16px;
}
.user_down button.gray{
  background-color: #999;
  color: #fff;
  border: 0;
  width: 100%;
  border-radius: 4px;
  padding: 15px 0;
  font-size: 16px;
}
.Theme_bjcolor {
  background-color: #ff7300 !important;
}
.pagetop .mail-log {
  position: absolute;
  /* margin-top: -2px; */
  padding: 0 25px 0 0px;
  height: 100%;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: box;
  display: flexbox;
  display: flex;
  color: #fff;
}
.pagetop .mail-log {
  float: left;
}
.pagetop h4 {
  /* margin-top: 16px; */
  font-size: 15px;
  display: inline-block;
  color: white;
  line-height: auto;
}

.pagetop .add {
  color: #fff;
  position: absolute;
  right: 5px;
  top: 0px;
  display: -webkit-flex;
  display: flex;
}
/* 课程开始 */
.pagetop {
	position: relative;
	width: 100%;
	height: 50px;
	top: 0px;
	z-index: 100;
	min-width: 320px;
  line-height: 50px;
  background: #fff;
  text-align: center;
}
.top_left{
  position: absolute;
  height: 100%;
  left: 15px;
  align-items: center;
  display: flex;
}
.pagetop h1 {
	font-size: 26px;
	display: inline-block;
	color: #000;
  line-height: auto;
  margin: 0 ;
  padding: 0 ;
}
.top_right{
  position: absolute;
  height: 100%;
  right: 15px;
  align-items: center;
  display: flex;
}
.top_right img{
  width: 130px;
}
.turn-img{
  padding-top: 20px;
  width: 100%;
}
.turn-img img{
  width: 100%;
}
.course_tab{
  background: #fff;
  padding-bottom: 30px;
}
.tab_two{
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
  overflow: auto;
}
.tab_two li{
  height: 60px;
  float: left;
  line-height: 60px;
}
.col-xs-5{
  width: 48%;
  background: #f9f9f9;
}
.modules span{
  font-size: 14px;
  vertical-align: middle;
  float: left;
  height: 60px;
}
.modules img{
  width: 60px;
  height: 60px;
  padding: 10px;
}
.course_content{
  width: 100%;
  padding: 0px 15px 15px 15px;
}
.course_title{
  padding-top: 30px;
  padding-bottom: 20px;
}
.course_title h1{
  font-size: 20px;
}
.course_body{
  padding: 20px 15px 30px 15px;
  box-shadow:1px 2px 2px 2px #f3f3f3; 
}
.course_body_header{
color: #000;
font-size: 16px;
line-height: 25px;
}
.course_body_header span{
  font-size: 16px;
  font-weight: bold;
}
.course_body_header label{
  background: #e8b887;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
}
.course_body_body{
  font-size: 14px;
  padding-top: 10px;
  line-height: 20px;
}
.course_body_footer{
  font-size: 14px;
  color: #000;
  display: flex;
  padding-top: 20px;
}
.course_body_footer-content {
  flex: 1 1;
}
.course_body_footer-extra {
  flex: 1 1;
  text-align: right;
  font-size: 14px;
}
.old_price{
  color: #999;
  text-decoration: line-through;
}
.new_price{
  color: #ff7400;
}
/* 课程结束 */

/* 课程列表开始 */
.course_list{
  width: 100%;
  padding-top: 50px;
}
.course_list_top img{
  width: 100%;
}
.item {
    position: relative;
    display: block;
    padding: 15px;
    overflow: hidden;
    background: #fff;
}
.pct-list-item.item {
  padding: 12px 16px;
}
.item__thumbnail-container {
  position: relative;
  float: left;
}
.item__thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  /* border-radius: 3px; */
}
.detail-cover, .imgWrap__img {
  position: relative;
  width: 100%;
}
.imgWrap__img {
  display: block;
  height: 100%;
}
.item__thumbnail-icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.item__thumbnail-icon-tag {
  font-size: 12px;
  background: rgba(50,50,51,.8);
  display: inline-block;
  color: #fff;
  padding: 4px 6px;
  border-radius: 12px;
  -webkit-transform: scale(.83);
  transform: scale(.83);
}
.item__detail {
  position: relative;
  padding-left: 10px;
  overflow: hidden;
}
.item-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.item-content .item-row {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.item-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: hidden;
}
.item-col:first-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.item__title-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  line-height: 0;
}
.item__title {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-block;
  line-height: 18px;
  vertical-align: middle;
  color: #323233;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-content .item-row .item__title {
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.brown-theme .item-content .last-learning {
  color: #c3a769;
}
.item__subtitle {
  width: 75%;
  margin-top: 5px;
  line-height: 12px;
  color: #999;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item__footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 10px);
}
.item__status {
  display: inline-block;
  padding: 0 8px;
  line-height: 12px;
  vertical-align: middle;
  color: #999;
  font-size: 12px;
  border-right: 1px solid #e5e5e5;
}
.item__status:first-of-type {
  padding-left: 0;
}
.item__status:last-of-type {
  border-right: none;
}
.text-red{
  color: red;
}
/* .item-col:last-child {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}
.item__footer-corner {
  position: relative;
  overflow: visible;
}
.item__footer-corner__tag-wrap {
  position: absolute; 
   top: -28px; 
   right: 0;
}
.item__footer-corner__text.text-red {
  border: 1px solid #c3a769;
  background-color: #fff!important;
  color: #c3a769;
}
.item__footer-corner__text.text-red {
  display: block;
  padding: 5px 8px;
  border-radius: 11px;
  font-size: 11px;
  background-color: rgba(0,179,137,.1);
}
.item__footer-corner__text {
  line-height: 12px;
  text-align: right;
  color: #999;
  font-size: 12px;
} */
.button_bottom{
  text-align: right;
  bottom: 0px;
  z-index: 999;
  position: fixed;
  width: 100%;
  z-index: 10;
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 -2px 10px 0 rgba(125,126,128,.16);
}

.button_bottom button {
  background-color: #c5a769;
  color: rgb(255, 255, 255);
  width: 70%;
  font-size: 16px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 30px;
  height: 40px;
  vertical-align: middle;
  align-items: center;
}

/* 课程列表结束 */

/*我的 开始*/
.my{
  width: 100%;
}
.my .gy_hedimg{
  width: 80px;
  height: 80px;
  display: inline-block;
  float: left;
}
.my .gy_hedimg img{
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
}
.headimg_name{
  width: 100px;
  display: block;
  padding-left: 20px;
  float: left;
  padding-top: 10px;
}
.my .headimg_li label{
  width: 100px;
  display: block;
}
.my .headimg_li label.class{
color: #ccc;
font-size: 14px;
margin-top: 5px;
}
.invitae{
  width: 100%;
  height: 40px;
}
.invitae .leftimg{
  float: left;
  display: block;
  width: 10%;
  height: 40px;
}
.invitae .leftimg img{
  float: left;
  vertical-align: middle;
  margin-top: 10px;
}
.rightcontent{
  width: 90%;
  float: left;
  line-height: 40px;
  }
.labelmessage{
  font-size: 16px;
  padding-left: 20px;
}
.labelmessage1{
  float: right;
  font-size: 14px;
  text-align: right;
}

/*我的 结束*/

/*视频播放页面开始*/
.vidoPlay{
  width: 100%;
  /* height: 200px; */
  position: relative;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox-content {
  clear: both;
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  /* padding: 15px 20px 20px 20px; */
  border-color: #e7eaec;
  -webkit-border-image: none;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}
.video-content {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}
.video-content .video-content--fixed {
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 1000;
  width: 100%;
}
.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000!important;
}
.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.video-cover--overlay:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background: rgba(0,0,0,.6);
}
.video-cover__icon-lock {
  width: 20px;
  height: 23px;
}
.imgWrap {
  position: relative;
  overflow: hidden;
}
.imgWrap__img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

}
.video-cover__tip {
  margin-top: 20px;
  color: #fff;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
}
.video-cover__tip>span {
  white-space: pre;
}
.content-info {
  position: relative;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
.content-info__main{
  width: 100%;
}
.content-info__firstline-share {
  min-height: auto;
}
.content-info__firstline{
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  min-height: 44px;
  position: relative;
  margin: 0 -16px 12px;
  padding: 0 16px;
}
.content-info__title{
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
  word-break: break-all;
  color: #323233;
}

.content-info__info{
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  position: relative;
  min-height: 24px;
}
.content-info__desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  position: relative;
}
.content-info__item{
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
}
.content-info__item-title {
  color: #969799;
  padding-right: 16px;
}
.content-info_content{

}

/*视频播放页面结束*/

/*公司信息开始*/
.companyinfo{
  padding: 15px 15px 80px 15px;
}
.companyinfo img{
  width: 100%;
}
/* 公司信息结束 */
/*问题反馈开始*/
.feedback{
  /* padding-top: 45px; */
  min-height: 200px;
}
.feedback textarea{
  width: 100%;
  resize:none;
  border: 0;
  padding:8px 10px;
  text-align: justify;
  text-justify: inter-ideograph;
  color: #000;
}
/*问题反馈结束*/
/*弹出框开始*/
.layui-m-layer * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.layui-m-layer {
  position: relative;
  z-index: 19891014;
}
.layui-m-layermain, .layui-m-layershade {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.layui-m-layershade {
  background-color: rgba(0,0,0,.7);
  pointer-events: auto;
}
.layui-m-layermain {
  display: table;
  font-family: Helvetica,arial,sans-serif;
  pointer-events: none;
}
.layui-m-layermain .layui-m-layersection {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.layui-m-anim-scale {
  animation-name: layui-m-anim-scale;
  -webkit-animation-name: layui-m-anim-scale;
}
.layui-m-layerchild {
  position: relative;
  display: inline-block;
  text-align: left;
  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(0,0,0,.1);
  pointer-events: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}
.layui-m-layer0 .layui-m-layerchild {
  width: 90%;
  max-width: 640px;
}


.layui-m-layercont {
  padding: 50px 30px;
  line-height: 22px;
  text-align: center;
}
.layui-m-layercont {
  padding: 1rem;
}
.fixed {
  position: fixed;
}
.fa, .fas {
  font-weight: 900;
}
.fa, .far, .fas {
  font-family: "Font Awesome 5 Free";
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-lg {
  color: #444;
  font-size: 1.33333em;
  line-height: .75em;
  vertical-align: -.0667em;
}
small {
  font-size: .85rem;
}
.hide{
  display: none;
}
.center, center {
  text-align: center;
}
.fa-image:before {
  content: "\f03e";
}
/* a.button.small, button.small {
  height: 2rem;
  font-size: 1rem;
  box-sizing: border-box;
  line-height: 1rem;
  padding: .5rem 1rem;
}
.qrcode a.button.inline, button.inline, input.inline {
  width: auto;
  display: inline-block;
}
.qrcode a.button.inline, button.inline {
  margin: 0 .1rem;
  vertical-align: middle;
} */
.qrcode .inline {
  width: auto;
  display: inline-block;
}
.qrcode button, .button{
  /* background: rgba(193, 26, 21, 0.83); */
  background-color: #ff7300;
}
.qrcode button {
  height: 2rem;
  margin: .5rem 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  outline: 0;
  border-radius: .15rem;
}
.qrcode button{
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 0;
  -webkit-appearance: none;
}

/*提现规则*/
.WithdrawalRule{
  padding: 15px;
  color: #666;
}
.WithdrawalRule h4{
  padding: 10px;
  text-align: center;
}
.WithdrawalRule p{
  line-height: 25px;
}
.WithdrawalRemark{
  padding: 15px;
  color: #666;
  font-size: 12px;
}
.WithdrawalQuan{
  padding-left: 10px;
  color: #ff7300 !important;
}
.am-modal-wrap{
  z-index: 1000 !important;
}
.am-modal-mask{
  z-index: 1000 !important;
}

.am-list-item .am-list-line .am-list-extra{
  flex-basis: 44% !important;
}