.error{
    width: 100%;
    height: 100%;
    min-height: 667px;
    background: #fff;
    text-align: center;
}
.result-example .spe {
    width: 60px;
    height: 60px;
  }
  .sub-title {
    color: #888;
    font-size: 14px;
    padding: 30px 0 18px 0;
    margin-left: 15px;
  }